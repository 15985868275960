
/*content and shapes*/
.icon .iconSquare{
    @apply flex items-center w-5 h-5 justify-center 
}
.icon:has(svg) {
    @apply inline-flex min-w-max p-0 justify-center items-center gap-2;

    > svg {
        @apply w-5 h-5;
    }
}
.icon{
    @apply rounded-full
} 
.iconSquare:has(svg) {
    @apply inline-flex min-w-max p-0 justify-center items-center gap-2;

    > svg {
        @apply shrink-0 w-5 h-5 relative overflow-visible;
    }
}

.iconSquare.medium{ 
    @apply  py-2 px-3 gap-0 flex flex-row items-center justify-center shrink-0 self-stretch relative overflow-hidden ; 
    > svg {
        @apply shrink-0 w-5 h-5 relative overflow-visible;
    }
}  

.iconSquare.large{ 
    @apply   p-2 lg:p-3 flex flex-row gap-2 items-center justify-center shrink-0 self-stretch  h-full relative overflow-hidden ; 
    > svg {
        @apply shrink-0 w-6 h-6 relative overflow-visible;
    }
    >span>div:has(svg) {
        >svg {
            @apply shrink-0 w-6 h-6 relative overflow-visible;
        }
    } 
}  

.icon.medium{
    @apply  p-[7px] flex flex-row gap-2 items-center justify-center shrink-0 h-9 w-9 relative overflow-hidden ; 
    > svg {
        @apply shrink-0 w-5 h-5 relative overflow-visible;
    }
}

.icon.medium:has(svg) {  
    > svg {
        @apply shrink-0 w-5 h-5 relative overflow-visible;
    }
}

.icon.large{
    @apply  p-[7px] flex flex-row gap-2 items-center justify-center shrink-0 h-14 w-14 relative overflow-hidden ; 
}

.icon.small { 
    @apply  p-[7px] flex flex-row gap-2 items-center justify-center shrink-0 h-7 w-7 relative overflow-hidden ; 
} 

/*Colors and styles*/

/*Error*/
.error.filled {
    @apply  bg-feedback-error-light fill-feedback-error-default hover:bg-feedback-error-default hover:fill-white text-feedback-error-default hover:text-white border-feedback-error-default border-solid !border-[1px];
}

.error.filled.disabled {
    @apply opacity-5 bg-feedback-error-light hover:bg-feedback-error-light cursor-not-allowed; 
}

.error.outlined {
    @apply bg-transparent border text-feedback-error-default fill-feedback-error-default border-feedback-error-default hover:bg-feedback-error-light;
}

.error.ghost {
    @apply bg-transparent border-transparent text-feedback-error-default fill-feedback-error-default hover:bg-feedback-error-light ;
}

/*Delete*/
.delete.filled {
    @apply bg-transparent text-neutral fill-current border-feedback-error bg-feedback-error hover:bg-feedback-error-default border-solid !border-[1px];
    >span:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    }
    >span>div:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    }
}
.delete.outlined {
    @apply bg-transparent text-feedback-error fill-current border-feedback-error hover:bg-feedback-error hover:text-neutral border-solid !border-[1px];
    >span:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    }
    >span>div:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    }
}

/*Primary*/
.primary.filled {
    @apply bg-accentDefault hover:bg-primary-dark  text-white  border-accentDefault border-solid !border-[1px];
}
.primary.filled:enabled {
    @apply opacity-5 bg-primary cursor-not-allowed;
}
.primary.filled.disabled {
    @apply opacity-5 bg-primary cursor-not-allowed; 
}
.primary.ghost {
    @apply bg-transparent hover:text-primary-dark fill-current text-primary disabled:opacity-5 ;
}
.primary.outlined {
    @apply bg-transparent border border-primary hover:border-primary-dark;
}


/*Excel*/
.excel.filled {
    @apply bg-[#008000] hover:bg-[#005700] disabled:bg-[#008000]  text-white disabled:opacity-5 border-[#008000] border-solid !border-[1px];
}


/* Accent */
.accent.filled {
    @apply bg-Default  hover:bg-accent-Default-dark disabled:hover:bg-Default text-white disabled:opacity-5 !border-Default !border-solid !border-[1px] ;
}
.accent.filled.disabled {
    @apply opacity-50 bg-Default cursor-not-allowed;
}
.accent.ghost {
    @apply bg-transparent hover:text-accent-Default-dark fill-current text-Default disabled:opacity-5 ;
}
.accent.outlined {
    @apply bg-transparent border border-Default hover:bg-accent-Default-dark
    hover:text-neutral fill-current text-Default disabled:opacity-5 ; 
}
.accent.outlined.disabled {
    @apply bg-transparent border border-Default opacity-50 cursor-not-allowed
    fill-current text-Default disabled:opacity-5 ; 
}

/* Neutral */
.neutral.filled {
    @apply bg-gray-800 hover:bg-neutral-dark  disabled:hover:bg-gray-800 text-white disabled:opacity-5 border-gray-800 border-solid !border-[1px];
}
.neutral.ghost {
    @apply bg-transparent hover:text-neutral-dark fill-current text-gray-font disabled:opacity-5 ;
}
.neutral.outlined {
    @apply bg-transparent border hover:text-neutral-dark border-gray-font fill-current   text-gray-font ;
}

/* sizes and text */

.small { 
    @apply text-xl leading-5 not-italic flex self-stretch h-full min-w-max pl-5 pr-4 py-[0.81rem] justify-center items-center gap-3;
    >span>div:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    } 
    >span:has(svg) {
        >svg {
            @apply w-5 h-5;
        }
    }
}



.medium {  

        @apply flex !min-h-[2rem] h-full w-full  p-2 pl-3 md:py-2 lg:pr-2 lg:p-3 justify-center items-center gap-1 md:gap-2 lg:gap-3 text-center font-decimal lg:text-lg  text-base not-italic font-medium leading-[1.125rem] lg:leading-4 ;   
        >span:has(svg){
            svg {
                @apply w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 lg:w-6 lg:h-6;
            }
        }
        >span>div:has(svg) {
            >svg {
                @apply w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 lg:w-6 lg:h-6;
            }
        } 
    } 
 
.dropdown {  

        @apply flex !min-h-[2rem] h-full w-auto  px-1 py-2 lg:py-3 justify-center items-center gap-1 md:gap-2 lg:gap-3 text-center font-decimal lg:text-lg  text-base not-italic font-medium leading-[1.125rem] lg:leading-4 ;   
        >span:has(svg){
            svg {
                @apply w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 lg:w-6 lg:h-6;
            }
        }
        >span>div:has(svg) {
            >svg {
                @apply w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 lg:w-6 lg:h-6;
            }
        } 
    } 
.large { 
    
    /* div size and text */
    @apply flex min-h-[1.75rem] h-full w-full p-2 pl-3 md:py-[0.81rem] lg:pr-4 lg:pl-5 gap-1 lg:gap-3 items-center justify-center self-end flex-1 text-center font-decimal text-base lg:text-lg  not-italic font-medium leading-[1.125rem] lg:leading-5;

    /* icon */
    >span>div:has(svg) {
        >svg {
            @apply w-[1.125rem] h-[1.125rem] lg:w-5 lg:h-5;
        }
    } 
    >span:has(svg) {
        >svg {
            @apply w-[1.125rem] h-[1.125rem] lg:w-5 lg:h-5;
        }
    }
} 

.filter{
  @apply flex min-h-[1.75rem] py-2 pl-3 pr-2 justify-center items-center gap-1 flex-1;
  >span>div:has(svg) {
        >svg {
            @apply w-[1.125rem] h-[1.125rem] lg:w-5 lg:h-5;
        }
    } 
    >span:has(svg) {
        >svg {
            @apply w-[1.125rem] h-[1.125rem] lg:w-5 lg:h-5;
        }
    }
}

/*General text atributes*/
.text,.textIcon {
    > button {
        @apply flex min-w-max justify-center items-center not-italic ;
    }  
    @apply flex items-center justify-center
}
.text,.textIcon:has(svg) {
    @apply  flex min-w-max justify-center items-center ;
    > svg {
        @apply w-5 h-5;
    }
}

.neutral.anchorbutton{
    @apply hover:fill-Default hover:text-Default  
}

.icon.neutral.anchorbutton.medium>span>svg{
    @apply h-10 w-10
}

/* Underline test */

/* linkbuttons */

/* .underlinelink { 
    @apply  relative transition-all duration-300 ease-in-out ; 
}   

.underlinelink:after { 
    position: absolute;
    content: '';
    height: 2px;
    bottom: -4px; 
    margin: 0 auto;
      left: 0; 
      width: 0%; 
      -o-transition:.3s;
        -ms-transition:.3s;
    -moz-transition:.3s;
    -webkit-transition:.3s;
    transition:.3s;
}  */

/* .neutral.ghost > .underlinelink:after {
    background: bg-gray-font;
} */
 
 /* optional hover classes used with anmiation */
 /* .underlinelink:hover:after {
    width: 100%;
} */