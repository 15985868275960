.grayText{
    font-weight: 700;
}
button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  position: relative;
}

.subrayado::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.subrayado-activo::before {
  width: 100%;
}