.loginFormContainer {
    width: 100%;
    min-height: 90vh;
    margin: auto;
    height: auto;
    display: grid;
    position: relative;
}
.loginFormbg{
  background-image: url('../../../public/bg-login.jpg');
  background-size: cover;
  background-position: center; 
  width: 100%;
  min-height: 90vh;
  position: absolute;
  z-index: -1;
}

.formLogin{
  width: 95%;
  @media(min-width: 768px){
    width: 560px;
  }
}

.robotoTitle{
  font-weight: 700;
  font-size: 23px;
}

.redTextWsg {
    color: #9C3325;
    font-weight: 500;
    @media (min-width: 768px) {
    justify-content: baseline;
    flex-direction: inherit
    };
  }
  .redwsg {
    background-color: #9C3325;
  }
  .grayWsgInput{ 
    background: #F6F6F6;
    color: black;
  }
  .grayWsgInput::placeholder {
    color: #666666;
    opacity: 0.8;
  }


  .buttonWsg {
    background: #BC3323;
    font-weight: 600;
    font-size: 15px;
  }


  .loginOptions{
    font-size: 12px;
  }

  #remember_user {
    border: 2px solid #882619;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      outline: none;
      margin-right: 5px;
      margin-top: 5px;
      vertical-align: middle;
  }
  
  #remember_user:checked {
    background-color: #882619;
    border-color: #882619;
  }
  
  .customLabel {
    font-weight: 400;
    color: black;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  .buttonToggle{
    font-weight: 500;
    font-size: 17px;
  }