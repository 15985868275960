.mainSection {
  @apply text-left pt-[1px] bg-gray-100 px-4 md:px-6 lg:px-10 pb-4 md:pb-6 lg:pb-10 min-h-screen;
}

.articleSection {
  @apply bg-white w-full flex flex-col gap-6 lg:gap-10 px-4 py-5 md:p-7 lg:p-10 items-start self-stretch;
}

.articleSectionRelative {
  @apply bg-white w-full flex flex-col gap-6 lg:gap-10 px-4 py-5 md:p-7 lg:p-10 items-start self-stretch relative;
}

.formsectionNTitle {
  @apply flex flex-col gap-6 justify-between items-start flex-1 w-full;
}

.formsection {
  @apply gap-5;
}

/* no se si las necesitamos por eso no las borre abria que probar si se estan usando por que no son responsabilidad del diseño */
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}