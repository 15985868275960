
  #profilePicture * {
    user-select: none;
  }
  #dragArea {
    width: 100%;
    height: 100%;
    cursor: move;
    cursor: grab;
    display: block;
    overflow: hidden;
    position: relative;
    background-color: #2c2c2c;
    background-repeat: repeat;
    margin: auto;
  }
  
  #dragArea:active {
    cursor: grabbing;
  }

  .CropArea>div{
    border:none !important
  }
  
 
/********** Range Input Styles **********/
input[type="range"] {
  -webkit-appearance: none;
   appearance: none;
   background: transparent;
   cursor: pointer;
   width: 100%;
   @media (min-width: 768px){
    width: 18rem;
  }
}



/* Removes default focus */
input[type="range"]:focus {
 outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #E9E9E9;
  border-radius: 0.5rem; 
  margin-top: 7px;
  height: 0.375rem;  
}

/* slider thumb */
.scaleSlider::-webkit-slider-thumb {
 -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -5px; /* Centers thumb on the track */
  /*custom styles*/
  background-color: #BC3323;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
} 

/* slider thumb */
.scaleSliderStaff::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
   appearance: none;
   margin-top: -5px; /* Centers thumb on the track */
   /*custom styles*/
   background-color: #0070cd;
   height: 1rem;
   width: 1rem;
   border-radius: 50%;
 } 

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #E9E9E9;
  border-radius: 0.5rem;
  margin-top: 11px;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  height: 1rem;
  width: 1rem;
  border-radius: 50%; 
  background-color: #BC3323;
}

 
  .rect {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    user-select: none;
  }
  
  .rect {
    width: 0px;
    height: 0px;
    position: absolute;
  }
  
  .rect:before {
    z-index: 2;
    width: 4px;
    height: 4px;
    content: '';
    display: block;
    position: absolute;
    border-radius: 100%;
  }
  
  #circleRect {
    background: rgba(255, 255, 255, 0.15);
  }
  


  
  .rect.tl {
    top: 0;
    left: 0;
  }
  
  .rect.tl:before {
    top: -2px;
    left: -2px;
  }
  
  .rect.tr {
    top: 0;
    right: 0;
  }
  
  .rect.tr:before {
    top: -2px;
    right: -2px;
  }
  
  .rect.br {
    right: 0;
    bottom: 0;
  }
  
  .rect.br:before {
    right: -2px;
    bottom: -2px;
  }
  
  .rect.bl {
    left: 0;
    bottom: 0;
  }
  
  .rect.bl:before {
    left: -2px;
    bottom: -2px;
  }

  
  
  #photo, #photo-clone {
    width: 100%; /* Ajusta el ancho de la imagen para que se ajuste al círculo */
    height: auto; /* Permite que la altura se ajuste automáticamente */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  img[src=''] {
    visibility: hidden;
  }
  
  #cropCircle {
    width: 20rem;
    height: 20rem;
    margin: auto auto;
    overflow: hidden;

    /*overflow: hidden;*/
    position: relative;
    border-radius: 50%;
    box-shadow: 0 0 0 2px transparent, 0 0 0 100vw rgba(0, 0, 0, 0.5);
    @media (min-width: 1024px){

      width: 28rem;
      height: 28rem;
    }
  }
  
  #circleThirds {
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    border-radius: 100%;
  }
  
  #circleThirds * {
    z-index: 1;
    position: absolute;
  }
  
  .topHorizontal {
    width: 100%;
    height: 1px;
    top: 33.33333%;
  }
  
  .bottomHorizontal {
    width: 100%;
    height: 1px;
    top: 66.66666%;
  }
  
  .leftVertical {
    height: 100%;
    width: 1px;
    left: 33.33333%;
  }
  
  .righVertical {
    height: 100%;
    width: 1px;
    left: 66.66666%;
  }
  
  .photoOptions {
    width: 100%;
    display: block;
    position: relative;
  }
  
  .optionButtons {
    width: 100%;
    position: relative;
  }
  
  .optionButtons button {
    width: max-content;
  }
  
  .optionButtons button + button {
    margin-left: 10px;
  }
  
  .photoOptions fieldset {
    margin: 0px;
  }
  
  .photoOptions fieldset + fieldset {
    margin-top: 10px;
  }
   
  
  .optionSlider input[type=range] {
    flex-shrink: 0;
  }
  
  .optionSlider input[type=number] {
    width: 20%;
    margin: 0 10px;
  }
 


  .customInputLabel {
    width: 100%;
    height: 3rem;
    color: #2c2c2c;
    font-size: .875rem;
    border: 1px solid #2c2c2c;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .75rem;
    @media(min-width: 1024px){
      width: max-content;
    }
  }

  .rotateClockwise{
    width: max-content;
  }

  .dragging {
    border: 2px dashed #007bff;
    background-color: rgba(0, 123, 255, 0.1);
    cursor: pointer;
  }

  
  .acceptedFiles {
    line-height: 1.2rem;
  }