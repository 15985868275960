.buttonToggle{
    font-weight: 500;
    font-size: 17px;
  }
  

.link {
  background-position: 2.45em;
  
  cursor: pointer;
  background-size: 0% .5px;
  background-repeat: no-repeat;
  background-image: linear-gradient(white, white);
  /* NOTE: this won't work with background images   */
  transition: all 500ms ease;


}
.link:hover {
  background-position: center 2.45em;
  background-size: 20% 1.5px;

  @media (min-width: 768px) {
    background-position: left 2.45em;
    background-size: 100% 1.5px;
    transition: all 250ms ease;
  }
}

.leftToRight {
  background-position: center 2.45em;
  @media (min-width: 768px) {
    background-position: left 2.45em;
  }
}
