 

h1{
    @apply  font-medium  leading-9 lg:leading-[2.75rem] font-decimal not-italic text-[2.25rem] md:text-[2.75rem] lg:text-[3.25rem]
} 

h2{
    @apply leading-7 md:leading-8 lg:leading-9  font-medium not-italic font-decimal text-2xl md:text-[1.75rem] lg:text-[2rem]
} 
h3{
    @apply    leading-5 md:leading-6 lg:leading-7 font-medium font-decimal text-lg md:text-xl lg:text-2xl not-italic
} 
h4{
    @apply leading-[1.125rem] font-semibold text-xl not-italic md:leading-6 md:font-bold lg:text-[1.375rem]
} 

.inputLabel{
    @apply  flex items-center text-center text-base leading-[1.125rem] lg:text-lg lg:leading-5 font-normal not-italic relative md:self-stretch 
}
 
 