/* Initial state for the dropdown menu */


table{
  @apply  w-full
}
thead{
  @apply bg-accent-Default-dark text-white p-1 
} 
 
th{ 
  text-wrap: nowrap !important;
  @apply relative not-italic !font-normal  !text-base lg:!text-lg leading-[1.125rem] lg:leading-[1.25rem] text-neutral !py-0 px-[.625rem] border-r-0 border-accent-Default-dark border-none
  
}
td{ 
  @apply p-4 text-left not-italic text-gray-font font-normal !text-[0.875rem] lg:!text-base !leading-4 lg:!leading-[1.5rem] relative;
}
.link{
  @apply text-left text-secondary underline text-xs font-normal leading-[.85rem] relative
}
thead>tr{
  @apply w-fit border-solid border-[1rem] border-accent-Default-dark
}

.edit{
  @apply flex items-center gap-2 p-2 pl-3 justify-start fill-current 
 
}
.editsvg{
  @apply w-5 h-5 shrink-0 relative overflow-visible fill-current
} 

.highlight {
  @apply border-r-2 border-solid border-gray-font transition duration-300 ease-in-out  
}
 
/* th:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  background: transparent;
} */
