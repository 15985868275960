/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.menu-bar {
  @apply flex flex-row w-full items-center whitespace-nowrap;
}

.menu-item {
  @apply flex hover:bg-[#004578] hover:border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap;
}

.menu-item:hover .menu-icon, .menu-item:hover .menu-text {
  color: #E5F3FF; /* Change text color on hover */
  fill: #E5F3FF; /* Change icon color on hover */
}

.menu-icon {
  @apply fill-accent-Default-dark w-4 h-4;
}

.menu-text {
  @apply text-center justify-center items-center text-base font-normal not-italic text-accent-Default-dark;
}
