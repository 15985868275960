.cardLayout {
  background-color: #fff;
  margin: 1rem auto;
  width: 100%;
  @media(min-width: 768px){
    width: 100%;
  }
  }
  
  .cardHeader {
    background-color: #BC3323;
    color: white;
  }
  
  .cardHeader h2 {
    width: 92%;
    padding: 2% 3% 2% 3%;
  }
  
  .editButton {
    width: 8%;
    background-color: #882619;
    background-image: url('../../../assets/images&icons/cross.svg');
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: center center;
    cursor: pointer;
  }
  
  .cardBody {
    padding: .75rem 1rem;

    min-height: 10rem;
    @media (min-width: 768px) {
      padding: 1.25rem 1rem;
      min-height: 16rem;
    }
  }
  
  .sectionContainer {
    width: 980.942px;
    height: 347.049px;
    flex-shrink: 0;
  }
  