.bgImage {
  height: 34.875rem;
  filter: grayscale(1);
  @media (min-width: 768px){
    background-size: 160%;
  
  }
  @media (min-width: 1024px){
  height: 35.25rem;
    background-size: cover;
  background-position-y: -6rem;

  }
}

.grayLayer {
  z-index: 9;
  height: 34.875rem;
  @media (min-width: 1024px){
    height: 35.25rem;
    }
}

/* .backgroundMyProfile{
filter: grayscale();
height: 53rem;
@media (min-width: 768px){
  height: 60rem;
  
}
@media (min-width: 1024px){
  height: 58rem;
}
@media (min-width: 1400px){
  background-position: 0 -160px;
  height: 53rem;
}
} */
