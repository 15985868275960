.hoverBlue:hover{
    color: #2366C2;
    font-size: 700;
}
.bgblue{
    background-color: #2366C2;
    color: #fff;
    
}

.bgblue svg {
    width: 1.25rem;
    height: 1.25rem;
}

.hoverBlue svg {
    width: 1.25rem;
    height: 1.25rem;
}
.hoverBlue svg path{
    fill: #BABABA;
}

.hoverBlue span:hover{
    color: #2366C2;
}

.bgblue:hover{
    background-color: #0144a1;
}
.bgblue span:hover{
    color: white;

}

.outlineCard{
    border: 1px solid #ccc;
}


.handleGaps {
    gap: 1rem;
    @media (min-width: 768px) {
    }
    @media(min-width: 1024px) {
    }
    @media(min-width: 1400px) {
    }


}

