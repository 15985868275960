

.blueContent {
    color: #2366C2;
    font-size: 0.625rem;
}

.selected {
    color: white;
    background-color: #BC3323;
}

.paddingGroups {
   @media (min-width: 1024px)
    { 
        padding: 0rem 1.5rem 0 1.5rem;
    }   
}

.groupButtonContainer::-webkit-scrollbar{
    display: none !important;
}