 
.currentButton{ 
    @apply flex w-7 p-1 flex-col items-center justify-center gap-2.5 rounded 
    text-neutral text-lg leading-5 font-normal not-italic;
}
 
.arrow{ 
    @apply  font-normal text-lg leading-5 not-italic text-gray-font;
}
   
.records-per-page{
    @apply text-gray-font text-base font-normal leading-[1.125rem] not-italic lg:text-lg lg:leading-5;

}

.pagination{
    @apply flex items-center justify-center md:justify-end gap-4 w-full text-gray-font
}

  
.selectorPageSize{
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    @apply flex flex-col text-gray-font items-start w-[74px] max-h-[2.5rem] min-h-[2.5rem] h-10 py-[0.625rem] pl-4 pr-3 gap-2 self-stretch bg-gray-25
}