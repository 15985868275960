
.rdp-caption{
    @apply flex justify-between
} 

/* Color de Bottones*/
 /* .rdp-button {
    @apply bg-red-500
 } */

 
/* .rdp-button_reset{
    @apply bg-pink-500
 } */

/* este css es para los dias  */
.rdp-day{
   @apply h-10 w-10 hover:bg-accentDefault hover:bg-opacity-25   rounded-full text-lg font-decimal 
} 
 /* este css es para los dias seleccionados  */
.rdp-day_selected{
   @apply bg-accentDefault h-10 w-10  hover:bg-opacity-100  text-neutral first:rounded-l-full last:rounded-r-full 
} 
/* first and last child  .rdp-day_selected*/
.rdp-day_range_middle {
   @apply !rounded-none
}
.rdp-cell {
   @apply p-0 min-w-min align-middle text-center
}

  .rdp-cell:has(>.rdp-day_range_middle )  {
   @apply bg-accentDefault  last:bg-transparent 
  } 

 .rdp-cell:has(>.rdp-day_range_end) {
   background-image: linear-gradient(to right, rgb(188, 51, 35) 40%, transparent 0%);  
   background-color: transparent;  
  }
  .rdp-cell:has(>.rdp-day_range_start) {
   background-image: linear-gradient(to left, rgb(188, 51, 35) 60%, transparent 0%);  
   background-color: transparent;
   
  }
   .rdp-cell:has(>.rdp-day_range_end) .rdp-cell:has(>.rdp-day_range_start) {
       @apply !bg-transparent w-10 
   }
  
.rdp-row{
   @apply p-0 min-w-min max-w-min
}
.rdp-head_cell{
   @apply p-0 min-w-min align-middle text-center
}
.rdp-tfoot{
   >tr>td{  @apply p-0
   }
}

.rdp-month{
   @apply flex flex-col shadow-lg
}

.rdp-vhidden , .rdp-caption_label{
   @apply hidden
}
.rdp-caption_dropdowns{
   @apply flex
}

.rdp-nav{ 
   @apply flex justify-around gap-5 text-neutral
}
.rdp-caption{
   @apply bg-accentDefault h-10 flex flex-row items-center justify-around
}

.rdp-dropdown{
   @apply bg-accentDefault rounded-none text-lg font-decimal text-neutral no-scrollbar
}
.rdp-dropdown>option {
   @apply bg-neutral text-gray-font checked:bg-accent-Default-dark checked:text-neutral
}

.rdp-head_row{
   @apply border-0 text-lg font-decimal text-neutral h-6
}