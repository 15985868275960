.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


input {
  font-family: 'Roboto', sans-serif;
}

.requiredField {
  color: #FF6109;
  padding-left: .3rem;
}
.image-container {
  overflow: hidden;
  width: 28rem;
  height: 28rem;
  margin: 50px auto;
  border-radius: 50%;
}

#bio:focus-visible {
  outline: none; /* Elimina el estilo de enfoque predeterminado */
}

#bio:focus {
  outline: 1px solid black;
  background-color: white; /* Elimina el estilo de enfoque predeterminado */
  transition: all 150ms ease-in-out;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .break-words-md {
    white-space: normal; /* Allow text to wrap */
  }
}

.editModeInput:focus-visible {
  outline: none; /* Elimina el estilo de enfoque predeterminado */
  border: none;
}

.editModeInput:focus {
  outline: 1px solid black;
  background-color: white;
  transition: all 150ms ease-in-out;
}
.errorInput {
  outline: #FF6109;
  outline: 1px solid #FF6109;
}

.editModeInputPhone:focus-visible{
  outline: none; /* Elimina el estilo de enfoque predeterminado */
  border: none;
}

.editModeInputPhone{
  outline: none; /* Elimina el estilo de enfoque predeterminado */
  border: none;
}

#professional-career {
  scroll-margin-top: 20px;
}

.pencilRed svg{
  fill: #BC3323;
}
.pencilWhite svg {
  fill: white;
}

.pencilBlue svg {
  fill: #0070CD;
}


.hoverFill svg{
  fill: #000;
}
.socialInactive svg {
  fill: #474F56 !important;
  opacity: .5 !important;
}
.hoverFill:hover svg{
  cursor: pointer;
  fill: rgb(156, 51, 37);
}



.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}


@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

.noscrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* styles.css */

.tabs-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE y Edge */
}

.tabs-container::-webkit-scrollbar {
  display: none;
}
