.sidebarPadding{
    gap: 0.5rem;
    color: #969696;
    @media(min-width: 768px){
        color: #BABABA;
    }
}

.borderBottom {
    @media (min-width: 1024px){
        border-bottom: 1px solid #CACACA;
    } 
  
}

.blueAnimation {
    background: linear-gradient(to right, #4F85CE 50%, #2366C2 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}

.blueAnimation:hover {
    background-position: left bottom;
  }