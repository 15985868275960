@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}


.redTextCard{
  color: #BC3323;
  font-weight: 600;
  text-transform: uppercase;
    font-size: 0.625rem;
}
.blueCardText{
  color: #2366C2;
  font-weight: 600;
  font-size: .5rem;

  text-transform: uppercase;
    font-size: 0.625rem;
}


.fileNameDisplay{
  width: 100%;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-SemiboldItalic.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-MediumItalic.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-Medium.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-BoldItalic.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-Bold.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-Semibold.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-LightItalic.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/Fonts/Decimal/Decimal-Light.woff2') format('woff2'),
      url('./assets/Fonts/Decimal/Decimal-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}